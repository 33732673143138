<template>
  <div>
    <a-card class="card" title="基本信息" :bordered="false">
      <div slot="extra">
        <a-button ><router-link :to="{ name: 'Factory'}">取消</router-link></a-button>
        <a-divider type="vertical" />
        <a-button type="primary" @click="handleSubmit">保存</a-button>
      </div>
      <a-form :form="form" layout="vertical"
              :labelCol="{ xs: { span: 20 }, sm: { span: 20 }}"
              :wrapperCol="{ xs: { span: 20 }, sm: { span: 20 }}">
        <a-row>
          <a-col :span="6">
            <a-form-item label="工厂简称">
              <a-input placeholder="" v-decorator="['name', { initialValue: factoryInfo && factoryInfo.name }]"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="简码">
              <a-input placeholder="" v-decorator="['abbr', { initialValue: factoryInfo && factoryInfo.abbr }]"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="客户简称">
              <a-select placeholder="选择客户简称" allowClear
                        v-decorator="['customer_id', { initialValue: factoryInfo && factoryInfo.customer_id }]">
                <a-select-option v-for="d in customerOps" :key="d.value">{{ d.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="工厂全称">
              <a-input placeholder="" v-decorator="['full_name', { initialValue: factoryInfo && factoryInfo.full_name, rules: [{ required: true, message: '请填写工厂全称!' }] }]"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="6">
            <a-form-item label="所在地区">
              <a-select placeholder="选择所在地区" allowClear
                        ref="districtRef"
                        v-decorator="['district_id', { initialValue: factoryInfo && factoryInfo.district_id, rules: [{ required: true, message: '选择所在地区!' }] }]"
                        @change="(v) => handleDistrictChange(v)">
                <a-select-option v-for="d in districtOps" :key="d.value">{{ d.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="所在地点">
              <a-select placeholder="选择所在地点" allowClear
                        ref="locationRef"
                        v-decorator="['location_id', { initialValue: factoryInfo && factoryInfo.location_id, rules: [{ required: true, message: '选择所在地点!' }] }]">
                <a-select-option v-for="d in locationOps" :key="d.value">{{ d.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="工厂地址" :wrapperCol="{ xs: { span: 22 }, sm: { span: 22 }}">
              <a-input placeholder="" v-decorator="['address', { initialValue: factoryInfo && factoryInfo.address }]"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="备注" :wrapperCol="{ xs: { span: 23 }, sm: { span: 23 }}">
          <a-textarea :autoSize="{ minRows: 2 }" v-decorator="['remark', { initialValue: factoryInfo && factoryInfo.remark }]"
          />
        </a-form-item>
      </a-form>
    </a-card>
    <a-card class="card" title="联系人设置" :bordered="false">
      <a-table bordered :dataSource="data"
               :columns="columns" :pagination="false"
               rowKey="id" size="small">
        <template slot="name" slot-scope="text, record">
          <a-input :value="text"
                   @change="e => handleChange(e.target.value, record.id, 'name')"/>
        </template>
        <template slot="tel_num" slot-scope="text, record">
          <a-input :value="text" @change="e => handleChange(e.target.value, record.id, 'tel_num')"/>
        </template>
        <template slot="mobile" slot-scope="text, record">
          <a-input :value="text" @change="e => handleChange(e.target.value, record.id, 'mobile')"/>
        </template>
        <template slot="email" slot-scope="text, record">
          <a-input :value="text" @change="e => handleChange(e.target.value, record.id, 'email')"/>
        </template>
        <template slot="is_default" slot-scope="text, record">
          <a-switch checkedChildren="是" unCheckedChildren="否" :checked="!!text" @change="e => handleChange(e, record.id, 'is_default')"/>
        </template>
        <template slot="remark" slot-scope="text, record">
          <a-input :value="text" @change="e => handleChange(e.target.value, record.id, 'remark', 'goods')"/>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            v-if="data.length"
            title="确认删除?"
            @confirm="() => onDelete(record.id)"
          >
            <a href="javascript:;"><a-icon type="delete" :style="{ fontSize: '18px' }"/></a>
          </a-popconfirm>
        </template>
      </a-table>
      <a-button style="width: 100%; margin-top: 16px; margin-bottom: 8px" type="dashed" icon="plus" @click="handleAdd">新增联系人</a-button>
    </a-card>
  </div>
</template>

<script>
import { getFirstLetter } from '@/utils/util'
import { getCustomerList } from '@/api/common'
import { getMetaOptions, saveMetaTypeInfo, getFactoryContractList } from '@/api/wms/meta'
export default {
  data() {
    return {
      form: this.$form.createForm(this, { onValuesChange: this.handleNameChange }),
      isEdit: false,
      customerOps: [],
      districtOps: [],
      locationOps: [],
      factoryInfo: null,
      data: [],
      columns: [{
          title: '操作',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          width: 60
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 80,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '固定电话',
          dataIndex: 'tel_num',
          width: 150,
          scopedSlots: { customRender: 'tel_num' }
        },
        {
          title: '手机号码',
          dataIndex: 'mobile',
          width: 150,
          scopedSlots: { customRender: 'mobile' }
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          scopedSlots: { customRender: 'email' }
        },
        {
          title: '是否默认',
          dataIndex: 'is_default',
          width: 100,
          scopedSlots: { customRender: 'is_default' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' }
        }]
    }
  },
  watch: {
    $route: function(newRoute) {
      if (!this.queryParam && newRoute.name === 'FactoryCur') { // 编辑页路由监控
        if (newRoute.params.id === 'create') { // 新增
          this.isEdit = false
          this.$route.meta.title = '新增工厂信息'
          this.factoryInfo = null
          this.form.resetFields()
          this.data = []
        } else {
          console.log('ffeeeeeeeee', newRoute)
          this.factoryInfo = newRoute.params
          this.getEditData(newRoute.params.id)
        }
      }
    }
  },
  created() {
    getCustomerList().then(v => {
      this.customerOps = v
    })
    getMetaOptions('district').then(d => {
      this.districtOps = d
    })
  },
  mounted() {
    if (this.$route.params.id !== 'create') {
      this.factoryInfo = this.$route.params
      this.getEditData(this.$route.params.id)
    }
  },
  methods: {
    getEditData(id) {
      this.isEdit = true
      this.$route.meta.title = '编辑工厂信息'
      this.form.setFieldsValue({
        name: this.factoryInfo.name,
        abbr: this.factoryInfo.abbr,
        customer_id: this.factoryInfo.customer_id,
        full_name: this.factoryInfo.full_name,
        district_id: this.factoryInfo.district_id,
        location_id: this.factoryInfo.location_id,
        address: this.factoryInfo.address,
        remark: this.factoryInfo.remark
      })
      this.handleDistrictChange(this.factoryInfo.district_id, this.factoryInfo.location_id)
      getFactoryContractList(this.$route.params.id).then(c => {
        this.data = c
      })
    },
    handleSubmit() {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = false
          const formInfo = {
            factory: Object.assign({}, values, {
              id: this.isEdit ? this.factoryInfo.id : '',
              district_name: this.$refs['districtRef'].$el.innerText,
              location_name: this.$refs['locationRef'].$el.innerText
            }),
            contact_list: this.data
          }
          saveMetaTypeInfo('factory', formInfo).then(v => {
            this.formVisible = false
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            })
            this.$router.push({
              name: 'Factory',
              params: formInfo.factory,
              query: {
                isEdit: this.isEdit
              }
            })
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleDistrictChange(val, secVal = '') {
      this.locationOps = []
      this.form.setFieldsValue({ 'location_id': secVal })
      if (val) {
        getMetaOptions('location', { district_id: val }).then(d => {
          this.locationOps = d
        })
      }
    },
    handleNameChange(a, field) {
      // console.log(this.form.getFieldValue('name'))
      if (field['name']) {
        const val = getFirstLetter(field['name'])
        this.form.setFieldsValue({ 'abbr': val })
      }
    },
    handleAdd() {
      const { data } = this;
      const newData = {
        id: Date.now(),
        name: '',
        tel_num: '',
        mobile: '',
        email: '',
        is_default: false,
        remark: ''
      };
      this.data = [...data, newData]
    },
    onDelete(key) {
      const data = [...this.data];
      this.data = data.filter(item => item.id !== key);
    },
    handleChange (value, key, column) {
      const newData = [...this.data]
      const target = newData.find(item => key === item.id)
      if (target) {
        target[column] = value
        this.data = newData
      }
    }
  }
}
</script>
